import { useEffect, useContext } from "react";
import { Router, Switch } from "react-router-dom";

import AddFlow from "./pages/AddFlow";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import Changes from "./pages/Changes";
import { createBrowserHistory } from "history";
import { firebase } from "./firebase/firebase";
import { UserContext } from "./contexts/UserContext";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import Toaster from "./components/Toaster/toaster";
import "./App.css";
import FlowDetail from "./pages/FlowDetail";

const history = createBrowserHistory();

function App() {
  const { setUser, setIsLoggingIn } = useContext(UserContext);

  const onAuthStateChanged = (user) => {
    console.log('User => ', user);
    // setIsLoggingIn(true);
    if (user) {
      user = user.toJSON();
      setUser(user);
    } else setUser(null);
    setIsLoggingIn(false);
  };

  useEffect(() => {
    const subscriber = firebase.auth().onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <PublicRoute exact path="/login" component={Login} />
          <PublicRoute exact path="/register" component={Register} />
          <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/add" component={AddFlow} />
          <PrivateRoute path="/flows/:flowId/companies/:companyId/changes/:urlNumber" component={Changes} />
          <PrivateRoute path="/flows/:flowId" component={FlowDetail} />
        </Switch>
      </Router>
      <Toaster />
    </div>
  );
}

export default App;
