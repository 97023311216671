import { makeStyles } from "@material-ui/core";
import { colors } from "../../constants/colors";

export const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  adornment: {
    display: "flex",
    flex: 1,
    background: colors.black,
    borderBottomRightRadius: "calc(100vw/4)",
    width: "50%",
    borderBottomRightRadius: "calc(100vw/4)",
    '@media(max-width: 480px)': {
      display: "none"
    }
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    width: "50%",
    '@media screen and (min-device-width: 481px) and (max-device-width: 768px)': {
      width: "70%",
    },
    '@media(max-device-width: 480px)': {
      width: "100%",
    }
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "410px",
    textAlign: "left",
  },
  header: {
    fontSize: "36px",
    lineHeight: "56px",
    fontWeight: 700,
    marginBottom: "5px",
  },
  subheader: {
    fontSize: "16px",
    lineHeight: "16px",
    color: colors.grey,
    "& a": {
      fontWeight: "700",
      color: colors.black,
    },
  },
  field: {
    marginTop: "20px",
  },
  options: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "10px",
    marginBottom: "20px",
    "& a": {
      fontWeight: "700",
      color: colors.grey,
    },
    "& span": {
      fontFamily: ["DM Sans", "sans-serif"],
      fontWeight: "400",
      color: colors.black,
    },
  },
  link: {
    fontWeight: "700",
    color: "#000",
    cursor: "pointer",
  },
}));
