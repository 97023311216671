import React, { useState, useContext } from "react";
import { FormGroup } from "@material-ui/core";
import { useStyles } from "./styles";
import TextInput from "../../components/TextField";
import Button from "../../components/Button";
import { sendPasswordResetEmail } from "../../firebase/firebase";
import { ToastContext } from "../../contexts/ToastContext";
import mapErrorCode from "../../utils/mapErrorCode";

const Login = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const { openToast } = useContext(ToastContext);

  const handleSubmit = async () => {
    try {
      await sendPasswordResetEmail(email);
      openToast('Password reset link sent to your email.', 'success');
      setEmail('');
    } catch (error) {
      console.error(error);
      openToast(mapErrorCode(error.code), 'error');
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setEmail(value);
  };

  return (
    <FormGroup>
      <div className={classes.root}>
        <div className={classes.adornment} />
        <div className={classes.container}>
          <div className={classes.content}>
            <div className={classes.header}>Forgot Password</div>
            <div className={classes.subheader}>
              Enter your email
            </div>
            <div className={classes.field}>
              <TextInput
                label="Email"
                placeholder="mail@example.com"
                labelSize="small"
                name="email"
                type="email"
                value={email}
                onChange={handleChange}
              />
            </div>
            <div className={classes.footer}>
              <Button onClick={() => handleSubmit()}>Login</Button>
            </div>
          </div>
        </div>
      </div>
    </FormGroup>
  );
};

export default Login;
