import { makeStyles } from "@material-ui/core";
import { colors } from "../../constants/colors";

export const useAccordionStyles = makeStyles(() => ({
    root: {
        background: colors.white,
        // border: 'none',
        borderRadius: '9px',
        boxShadow: '0px 18px 40px -12px rgba(112, 144, 176, 0.12)',
        marginBottom: '20px',
        '&:before': {
            display: 'none',
        },
    },
}));

export const useAccordionSummaryStyles = makeStyles(() => ({
    root: {
        background: colors.white,
        border: 'none',
        borderRadius: '9px',
    },
}));

export const useAccordionDetailStyles = makeStyles(() => ({
    root: {
        background: colors.white,
        border: 'none',
    },
}));
