import React, { useState, useContext, useEffect } from "react";
import { Link } from "@material-ui/core";
import { useStyles } from "./styles";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { getFlows } from "../../firebase/firebase";
import { UserContext } from "../../contexts/UserContext";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FlowOuterSummary from "../../components/FlowSummary/flowOuterSummary";

const Home = () => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const [flows, setFlows] = useState(UserContext);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    fetchFlows();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFlows = async () => {
    try {
      setIsFetching(true);
      if (user && user.uid) {
        const flows = await getFlows(user.uid);
        console.log("List of flows for the user => ", flows);
        setFlows(flows);
      }
      else {
        setFlows([]);
      }
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.root}>
      <Sidebar />
      <div className={classes.content}>
        <Header>
          <Link underline="hover" color="inherit">
            Home
          </Link>
        </Header>
        {flows.length
          ? flows.map((item, index) => {
            return (
              <FlowOuterSummary
                key={index}
                flowName={item.flowName}
                companies={item.companies}
                fetchFlows={fetchFlows}
                classes={classes}
                flowId={item.id}
                page="view"
              />
            );
          })
          : !isFetching && <Alert severity="info">No saved flows.</Alert>}
      </div>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isFetching}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  );
};

export default Home;
