import { makeStyles } from "@material-ui/core";
import { colors } from "../../constants/colors";

export const useSummaryStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    gap: "10px"
  },
  input: {
    width: "28%",
  },
  label: {
    // flexGrow: 1,
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    lineHeight: "26px",
    fontWeight: 700,
    "@media screen and (max-width: 768px)": {
      fontSize: "14px",
    },
    "@media screen and (max-width: 480px)": {
      fontSize: "12px",
    },
  },
  status: {
    // flexGrow: 2,
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    lineHeight: "26px",
    fontWeight: 500,
    // TODO: Handle "NO CHANGE" status color
    color: colors.green,
    "@media screen and (max-width: 768px)": {
      fontSize: "14px",
    },
    "@media screen and (max-width: 480px)": {
      fontSize: "12px",
    },
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export const useDetailsStyles = makeStyles(() => ({
  row: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  root: {
    display: "flex",
    padding: "15px 35px",
    gap: "10px",
    "@media screen and (max-width: 480px)": {
      flexDirection: "column"
    },
  },
  label: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    lineHeight: "26px",
    "@media screen and (max-width: 768px)": {
      fontSize: "14px",
    },
    "@media screen and (max-width: 480px)": {
      fontSize: "12px",
    },
  },
  status: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    lineHeight: "26px",
    fontWeight: 500,
    // TODO: Handle "NO CHANGE" status color
    color: colors.green,
    "@media screen and (max-width: 768px)": {
      fontSize: "14px",
    },
    "@media screen and (max-width: 480px)": {
      fontSize: "12px",
    },
  },
  error: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    lineHeight: "26px",
    fontWeight: 500,
    color: colors.red,
    "@media screen and (max-width: 768px)": {
      fontSize: "14px",
    },
    "@media screen and (max-width: 480px)": {
      fontSize: "12px",
    },
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "15px"
  },
}));
