import { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";

const PublicRoute = (props) => {
  const { user, isLoggingIn } = useContext(UserContext);

  if (isLoggingIn) return null;

  return user ? (
    <Redirect
      to={{
        pathname: "/home",
      }}
    />
  ) : (
    <Route {...props} />
  );
};

export default PublicRoute;
