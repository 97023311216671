import firebase from "firebase/compat/app";
import {
  getFirestore,
  collection,
  query,
  addDoc,
  where,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  getDoc,
  serverTimestamp,
  orderBy,
} from "firebase/firestore";
import "firebase/compat/auth";
import { getStorage, ref, uploadBytes, getDownloadURL, getMetadata } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBq9l1zvVc8zAwldR2jQI9Kw4QQ_TPPAjM",
  authDomain: "tracklings.firebaseapp.com",
  databaseURL: "https://tracklings-default-rtdb.firebaseio.com",
  projectId: "tracklings",
  storageBucket: "tracklings.appspot.com",
  messagingSenderId: "1087824969712",
  appId: "1:1087824969712:web:113b0c747d20a94f762735",
  measurementId: "G-VDCV9NVZXQ",
};

firebase.initializeApp(firebaseConfig);
const db = getFirestore();
const storage = getStorage();
const auth = firebase.auth();

const signInWithEmailAndPassword = async (email, password) => {
  await auth.signInWithEmailAndPassword(email, password);
};

const registerWithEmailAndPassword = async (companyName, email, password) => {
  const res = await auth.createUserWithEmailAndPassword(email, password);
  const user = res.user;
  await addDoc(collection(db, "users"), {
    uid: user.uid,
    companyName,
    email,
  });
};

const logout = () => {
  auth.signOut();
};

const addFlow = async (userId, flowName, companyName, urlInfo) => {
  const docRef = await addDoc(collection(db, "flows"), {
    userId,
    flowName,
    created: serverTimestamp(),
    updated: serverTimestamp(),
  });
  await addDoc(collection(docRef, "companies"), {
    companyName,
    urlInfo,
    created: serverTimestamp(),
    updated: serverTimestamp(),
  });
  return docRef;
};

const updateFlow = async (flowId, companyId, companyName, urlInfo) => {
  const response = await updateDoc(
    doc(db, "flows", flowId, "companies", companyId),
    {
      companyName,
      urlInfo,
      updated: serverTimestamp(),
    }
  );
  return response;
};

const deleteCompany = async (flowId, companyId) => {
  const response = await deleteDoc(
    doc(db, "flows", flowId, "companies", companyId)
  );
  return response;
};

const deleteFlow = async (flowId) => {
  return await deleteDoc(doc(db, "flows", flowId));
};

const getFlows = async (uid) => {
  const querySnapshot = await getDocs(
    query(collection(db, "flows"), where("userId", "==", uid), orderBy("created", "desc"))
  );
  var flows = querySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
  var flowsWithCompanies = [];
  for (const flow of flows) {
    const querySnapshot = await getDocs(
      query(collection(db, "flows", flow.id, "companies"), orderBy("created", "desc"))
    );
    const companies = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
    flowsWithCompanies.push({ ...flow, companies });
  }
  return flowsWithCompanies;
};

const getFlow = async (flowId) => {
  const flowSnapshot = await getDoc(doc(db, "flows", flowId));
  const flow = { ...flowSnapshot.data(), id: flowSnapshot.id };
  var flowWithCompanies = [];
  const companySnapshot = await getDocs(
    query(collection(db, "flows", flowId, "companies"), orderBy("created", "desc"))
  );
  const companies = companySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
  flowWithCompanies.push({ ...flow, companies });
  return flowWithCompanies;
};

const getMetaFromUrl = async (url) => {
  if (!url) {
    return {};
  }
  const fileRef = ref(storage, url);
  const metaData = await getMetadata(fileRef);
  return { createdAt: metaData.timeCreated, updatedAt: metaData.updated };
}

const getUrlDetails = async (flowId, companyId, urlNumber) => {
  const flowSnapshot = await getDoc(doc(db, "flows", flowId));
  const flow = { ...flowSnapshot.data(), id: flowSnapshot.id };
  const companySnapshot = await getDocs(
    collection(db, "flows", flowId, "companies")
  );
  var urlDetails = {};
  for (let i = 0; i < companySnapshot.docs.length; i++) {
    const doc = companySnapshot.docs[i];
    if (doc.id === companyId) {
      const company = doc.data();
      const urlInfo = company.urlInfo[urlNumber];
      const companyUpdatedAt = new Date(company.updated.seconds * 1000)
      urlInfo["imageUrlMeta"] = await getMetaFromUrl(urlInfo.imageUrl);
      urlInfo["referenceImageMeta"] = await getMetaFromUrl(urlInfo.referenceImage);
      urlDetails = { flowName: flow.flowName, companyName: company.companyName, companyUpdatedAt: companyUpdatedAt, ...urlInfo };
      break;
    }
  }
  return urlDetails;
};

const uploadImage = async (remoteImgUrl) => {
  var filename = "images/" + Date.now() + "/imageUrl.png";

  const response = await fetch("https://" + remoteImgUrl, {
    method: "GET",
    headers: {},
  });
  const buffer = await response.arrayBuffer();
  const blob = new Blob([buffer]);

  const imageRef = ref(storage, filename);
  const snapshot = await uploadBytes(imageRef, blob);
  const imgUrl = await getDownloadURL(snapshot.ref);
  console.log("Firebase storage image uploaded: ", imgUrl);

  return imgUrl;
};

const addCompanyToFlow = async (flowId, companyName, urlInfo) => {
  await addDoc(collection(db, "flows", flowId, "companies"), {
    companyName,
    urlInfo,
    created: serverTimestamp(),
    updated: serverTimestamp(),
  });
  await updateDoc(doc(db, "flows", flowId), {
    updated: serverTimestamp(),
  });
};

const editFlowName = async (flowId, flowName) => {
  await updateDoc(doc(db, "flows", flowId), {
    flowName,
    updated: serverTimestamp(),
  });
};

const getSidebarFlowNames = async (uid) => {
  const querySnapshot = await getDocs(
    query(collection(db, "flows"), where("userId", "==", uid), orderBy("created", "desc"))
  );
  var flows = querySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
  return flows;
};

const sendPasswordResetEmail = async (email) => {
  await auth.sendPasswordResetEmail(email);
};

export {
  firebase,
  signInWithEmailAndPassword,
  registerWithEmailAndPassword,
  logout,
  addFlow,
  getFlows,
  getFlow,
  getUrlDetails,
  uploadImage,
  updateFlow,
  deleteCompany,
  deleteFlow,
  addCompanyToFlow,
  editFlowName,
  getSidebarFlowNames,
  sendPasswordResetEmail,
};
