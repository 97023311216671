import React from 'react';
import { Button as MUIButton } from "@material-ui/core";

import { useStyles } from './styles';

const Button = ({ variant = 'primary', children, onClick }) => {
    const classes = useStyles({ variant });

    return (
        <MUIButton classes={{ root: classes.root }} onClick={onClick} variant="outlined" color="primary">
            {children}
        </MUIButton>
    );
};

export default Button;