import React, { useState, useContext } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { useStyles } from "./styles";
import TextInput from "../../components/TextField";
import Button from "../../components/Button";
import { registerWithEmailAndPassword } from "../../firebase/firebase";
import { ToastContext } from "../../contexts/ToastContext";
import mapErrorCode from "../../utils/mapErrorCode";

const Register = ({ history }) => {
  const classes = useStyles();
  const [userData, setUserData] = useState({
    companyName: "",
    email: "",
    password: "",
  });
  const { companyName, email, password } = userData;
  const { openToast } = useContext(ToastContext);

  const handleSubmit = async () => {
    console.log("Submitting data => ", userData);
    if (!companyName) {
      return openToast("Please enter a company name!", "error");
    };

    try {
      await registerWithEmailAndPassword(companyName, email, password);
      setUserData({
        ...userData,
        companyName: "",
        email: "",
        password: "",
      });
    } catch (error) {
      console.error(error);
      openToast(mapErrorCode(error.code), 'error');
    }
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  return (
    <FormGroup>
      <div className={classes.root}>
        <div className={classes.adornment} />
        <div className={classes.container}>
          <div className={classes.content}>
            <div className={classes.header}>Sign up with your email</div>
            <div className={classes.subheader}>
              Already have an account?{" "}
              <span
                className={classes.link}
                onClick={() => history.push("login")}
              >
                Log in
              </span>
            </div>
            <div className={classes.field}>
              <TextInput
                label="Name"
                placeholder="Company name"
                labelSize="small"
                name="companyName"
                type="text"
                value={companyName}
                onChange={handleChange}
              />
            </div>
            <div className={classes.field}>
              <TextInput
                label="Email"
                placeholder="mail@example.com"
                labelSize="small"
                name="email"
                type="email"
                value={email}
                onChange={handleChange}
              />
            </div>
            <div className={classes.field}>
              <TextInput
                label="Password"
                placeholder="Min. 8 characters"
                labelSize="small"
                name="password"
                type="password"
                value={password}
                onChange={handleChange}
              />
            </div>
            <div className={classes.options}>
              <FormControlLabel
                control={<Checkbox defaultChecked color="default" />}
                label="I agree with Terms and Privacy"
              />
            </div>
            <Button onClick={() => handleSubmit()}>Sign Up</Button>
          </div>
        </div>
      </div>
    </FormGroup>
  );
};

export default Register;
