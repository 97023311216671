import { makeStyles } from "@material-ui/core";
import { colors } from "../../constants/colors";

export const useStyles = makeStyles(() => ({
    root: {
        padding: '30px 50px',
        background: colors.white,
        borderRadius: '9px',
        boxShadow: '0px 18px 40px -12px rgba(112, 144, 176, 0.12)',
        '@media(max-width: 480px)': {
            padding: '20px 20px',
        }
    },
}));
