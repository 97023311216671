import { makeStyles } from "@material-ui/core";
import { colors } from "../../constants/colors";

const stylesByVariant = {
    small: {
        fontSize: '14px',
        lineHeight: '16px',
    },
    medium: {
        fontSize: '22px',
        lineHeight: '32px',
    },
};

export const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '& .MuiOutlinedInput-root': {
            borderRadius: '9px',
            '&.Mui-focused fieldset': {
                borderColor: colors.black,
            },
        },
    },
    label: {
        display: 'flex',
        alignItems: 'left',
        fontSize: ({ labelSize }) => stylesByVariant[labelSize].fontSize,
        lineHeight: ({ labelSize }) => stylesByVariant[labelSize].lineHeight,
        fontWeight: 700,
        marginBottom: '10px',
    },
}));
