import React from "react";

import TextField from "@material-ui/core/TextField";

import { useStyles } from "./styles";
import { FormControl } from "@material-ui/core";

const TextInput = ({ label, placeholder, labelSize = "medium", ...props }) => {
  const classes = useStyles({ labelSize });

  return (
    <FormControl className={classes.root}>
      <div className={classes.label}>{label}</div>
      <TextField placeholder={placeholder} variant="outlined" {...props} />
    </FormControl>
  );
};

export default TextInput;
