import React, { createContext, useState } from "react";

const UserContext = createContext();

const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(true);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isLoggingIn,
        setIsLoggingIn,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
