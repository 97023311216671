import React, {
  useEffect,
  useState,
  useContext,
} from "react";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Link } from "@material-ui/core";
import CancelIcon from "@mui/icons-material/Cancel";
import { colors } from "../../constants/colors";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDetailsStyles, useSummaryStyles } from "./styles";
import Accordion from "../Accordion";
import Button from "../Button";
import MUIButton from "@mui/material/Button";
import EditFlow from "./editFlow";
import { withRouter } from "react-router-dom";
import { saveAs } from "file-saver";
import { deleteCompany, updateFlow } from "../../firebase/firebase";
import { ToastContext } from "../../contexts/ToastContext";
import { trackWebsite } from "../../services/flowServices";
import addhttp from "../../utils/addHttp";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextInput from "../TextField";
import isNMinutesPassed from "../../utils/dt";

const FlowSummary = ({
  companyName: company,
  companyUpdatedAt,
  urlInfo,
  history,
  flowId,
  companyId,
  fetchFlows,
  page,
}) => {
  const summaryClasses = useSummaryStyles();
  const detailsClasses = useDetailsStyles();
  const { openToast } = useContext(ToastContext);

  const [oldIsOpen, setOldIsOpen] = useState(false); // Tracks the isOpen state before editing.
  const [isOpen, setIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [companyName, setCompanyName] = useState(company);
  const [editMode, setEditMode] = useState(false);

  const [inputFields, setInputFields] = useState(urlInfo);
  useEffect(() => {
    setInputFields(urlInfo);
  }, [urlInfo]);

  const handleEdit = (event) => {
    event.stopPropagation();
    setEditMode(true);
    setOldIsOpen(isOpen);
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const handleDeleteCompany = (event) => {
    event.stopPropagation();
    deleteCompany(flowId, companyId).then((r) => {
      fetchFlows().then((r) => r);
    });
  };

  const handleView = (event) => {
    event.stopPropagation();
    if (!editMode) {
      setIsOpen(!isOpen);
    }
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setEditMode(false);
    setIsOpen(oldIsOpen);
  };

  const calculateScreensChanged = () => {
    var value = 0;
    inputFields.forEach((item) => {
      if (item.changes > 0) {
        value = value + 1;
      }
    });
    return value;
  };

  const handleInputChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  const removeFields = (index) => {
    const data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const addFields = () => {
    let newfield = { urlTitle: "", url: "" };
    console.log("Input fields => ", inputFields);
    setInputFields([...inputFields, newfield]);
  };

  const downloadImage = (url) => {
    saveAs(url, "tracking-image.png");
  };

  const quickDownload = () => {
    urlInfo.forEach((item) => {
      saveAs(item.imageUrl);
    });
  };

  const markCompanySeen = () => {
    const seen = inputFields.map((item) => {
      if (item.referenceImage)
        return {
          ...item,
          imageUrl: item.referenceImage,
          referenceImage: null,
          differenceImage: null,
          changes: 0,
        };
      return item;
    });
    setInputFields(seen);
    setTimeout(() => {
      updateFlow(flowId, companyId, companyName, seen).then((r) => r);
    }, 0);
  };

  const handleDeleteUrl = (event, index) => {
    event.stopPropagation();
    const urls = inputFields.filter((item, i) => index !== i);
    console.log(urls);
    setInputFields(urls);
    setTimeout(() => {
      updateFlow(flowId, companyId, companyName, urls).then((r) => r);
    }, 0);
  };

  const markUrlSeen = (url) => {
    const urls = inputFields.map((item) => {
      if (item.imageUrl === url.imageUrl && item.referenceImage)
        return {
          ...item,
          imageUrl: item.referenceImage,
          referenceImage: null,
          differenceImage: null,
          changes: 0,
        };
      return item;
    });
    setInputFields(urls);
    setTimeout(() => {
      updateFlow(flowId, companyId, companyName, urls).then((r) => r);
    }, 0);
  };

  const submit = async () => {
    try {
      console.log("Submit: ", inputFields, companyName);
      if (!companyName) {
        return openToast("Invalid or empty field(s)", "error");
      }
      if (inputFields.find((item) => !item.url || !item.urlTitle)) {
        return openToast("Invalid or empty field(s)", "error");
      }

      setIsFetching(true);

      //Fetch initial response data from the tracking API for the flow
      var newUrlInfo = [];
      for (let flow of inputFields) {
        flow = { ...flow, url: addhttp(flow.url) };
        console.log("This flow => ", flow);
        setTimeout(() => {
          trackWebsite({
            flowId: flowId,
            url: flow.url,
            deviceType: "desktop",
            image: "",
          }).then((r) => console.log(r));
        }, 0);
        newUrlInfo.push(flow);
      }
      setInputFields(newUrlInfo);

      //Save the flow in the database along with initial tracking info
      await updateFlow(flowId, companyId, companyName, newUrlInfo);
      console.log("Data updated in firestore");
      setIsFetching(false);
      fetchFlows();
      openToast("Flow saved successfully!", "success");
      setEditMode(false);
    } catch (error) {
      console.log("Error while adding flow: ", error);
      setIsFetching(false);
      openToast("Internal server error.", "error");
    }
  };

  const summary = (
    <div className="accWrapper" style={{ width: "100%" }}>
      <div
        className={summaryClasses.root}
        onClick={handleView}
      >
        {editMode ? (
          <div
            className={summaryClasses.input}
            onClick={(e) => e.stopPropagation()}
          >
            <TextInput
              placeholder="Please enter company name"
              name="companyName"
              label="Company Name"
              value={companyName}
              onChange={(e) => {
                e.stopPropagation();
                setCompanyName(e.target.value);
              }}
            />
          </div>
        ) : (
          <div className={summaryClasses.label}>
            {company}
            <ExpandMoreIcon sx={{ fontSize: "30px" }} />
          </div>
        )}
        {!editMode && (
          <div className={summaryClasses.status}>
            {calculateScreensChanged()} screen changed
          </div>
        )}
        <div className={summaryClasses.buttons}>
          {!editMode ? (
            <>
              <div className={summaryClasses.quickDownloadButton}>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    quickDownload();
                  }}
                >
                  Download
                </Button>
              </div>
              <Box ml={2}>
                <Button
                  variant="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    markCompanySeen();
                  }}
                >
                  <DoneAllIcon />
                  &nbsp; Mark as Seen
                </Button>
              </Box>
              <EditIcon
                onClick={(event) => handleEdit(event)}
                sx={{ fontSize: "30px", marginLeft: "20px" }}
              />
              <Box ml={2}>
                <DeleteIcon onClick={handleDeleteCompany} />
              </Box>
            </>
          ) : (
            <Box ml={2}>
              <CancelIcon fontSize="large" onClick={handleClose} />
            </Box>
          )}
        </div>
      </div>
    </div>
  );

  const details = !editMode ? (
    <div className={detailsClasses.row}>
      {inputFields.map((item, index) => {
        return (
          <div
            key={index}
            className={detailsClasses.root}
          >
            <div className={detailsClasses.label}>{item.urlTitle}</div>
            {item.error === true || (!item.imageUrl && isNMinutesPassed(companyUpdatedAt)) ? (
              <div className={detailsClasses.error}>
                {`Error: ${item.url}`}
              </div>
            ) : (
              <div className={detailsClasses.status}>
                {item.changes > 0 ? 1 : 0} screen changed
              </div>
            )}
            <div className={detailsClasses.buttons}>
              <Link href={`/flows/${flowId}/companies/${companyId}/changes/${index}`} underline="none" color="inherit">
                <Button variant="primary">
                  View
                </Button>
              </Link>
              <Button
                variant="secondary"
                onClick={() => {
                  downloadImage(item.imageUrl);
                }}
              >
                Download
              </Button>
              <Button
                variant="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  markUrlSeen(item);
                }}
              >
                <DoneIcon />
              </Button>
              <MUIButton
                sx={{ float: "right", color: colors.black }}
                onClick={(event) => handleDeleteUrl(event, index)}
              >
                <DeleteIcon />
              </MUIButton>
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <div className={detailsClasses.row}>
      <>
        <EditFlow
          inputFields={inputFields}
          setInputFields={setInputFields}
          handleInputChange={handleInputChange}
          removeFields={removeFields}
          addFields={addFields}
          submit={submit}
        />
        <div>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isFetching}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </>
    </div>
  );

  return (
    <Accordion
      summary={summary}
      details={details}
      isOpen={isOpen}
    />
  );
};

export default withRouter(FlowSummary);
