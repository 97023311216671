import { makeStyles } from "@material-ui/core";
import { colors } from "../../constants/colors";

export const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "240px",
    backgroundColor: colors.black,
    color: colors.grey,
    padding: "50px",
    margin: "0",
    padding: "0",
    position: "fixed",
    height: "100%",
    overflow: "auto",
    "@media screen and (max-width: 700px)": {
      width: "100%",
      height: "auto",
      position: "relative",
    },
    "@media screen and (max-width: 400px)": {
      textAlign: "center",
      float: "none"
    }
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    height: "85vh",
    marginTop: "35px",
    "@media screen and (max-width: 700px)": {
      width: "100%",
      height: "auto",
      flexDirection: "row"
    },

  },
  navItem: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    alignItems: "center",
    cursor: "pointer",
    padding: "20px",
    "@media screen and (max-width: 480px)": {
      padding: "10px",
      gap: "5px",

    }
  },
}));
