import { makeStyles } from "@material-ui/core";
import { colors } from "../../constants/colors";

export const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    background: colors.grey2,
    width: "100vw",
    height: "100%",
    overflowY: "hidden",
    "@media screen and (max-width: 700px)": {
      flexDirection: "column"
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "55px 30px 30px 30px",
    minHeight: "90vh",
    alignItems: "left",
    overflowY: "auto",
    marginLeft: "240px",
    "@media screen and (max-width: 700px)": {
      marginLeft: "0px",
      width: "auto"
    },
  },
  header: {
    paddingTop: "20px",
    paddingBottom: "30px",
    fontSize: "34px",
    lineHeight: "42px",
    fontWeight: "700",
    textAlign: "left",
  },
  flowTitle: {
    width: "205px",
    height: "72px",
    "@media screen and (max-width: 480px)": {
      marginLeft: "0px",
      width: "100%"
    },

  },
}));
