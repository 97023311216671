import React from 'react';
import { Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { useStyles } from './styles';

const Header = ({ children }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                {children}
            </Breadcrumbs>
        </div>
    );
};

export default Header;