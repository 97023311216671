export const colors = {
    white: '#FFFFFF',
    white2: '#E6E8E9',
    black: '#0D1F2A',
    black2: '#3D4B54',
    grey: '#A3AED0',
    grey2: '#F7FAFF',
    greyMediumLight: '#949494 ',
    green: '#05CD99',
    red: '#F94E4E',
};
