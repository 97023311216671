import { Accordion as MUIAccordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import React from 'react';

import { useAccordionDetailStyles, useAccordionStyles, useAccordionSummaryStyles } from './styles';

const Accordion = ({ summary, details, isOpen }) => {
  const accordionClasses = useAccordionStyles();
  const accordionSummaryClasses = useAccordionSummaryStyles();
  const accordionDetailClasses = useAccordionDetailStyles();

  return (
    <MUIAccordion
      classes={accordionClasses}
      expanded={isOpen}
    >
      <AccordionSummary classes={accordionSummaryClasses}>
        {summary}
      </AccordionSummary>
      <AccordionDetails classes={accordionDetailClasses}>
        {details}
      </AccordionDetails>
    </MUIAccordion>
  );
};

export default Accordion;