const mapAuthCodeToMessage = (authCode) => {
  switch (authCode) {
    case "auth/invalid-password":
      return "Password provided is invalid.";

    case "auth/invalid-email":
      return "Email provided is invalid.";

    case "auth/email-already-in-use":
      return "Email already in use!";

    case "auth/user-not-found":
      return "No user found with the provided credentials.";

    case "auth/weak-password":
      return "Password should be at least 6 characters.";

    case "auth/wrong-password":
      return "Password provided is invalid.";

    case "auth/missing-email":
      return "Please enter an email.";

    default:
      return "Request failed, please try again.";
  }
};

export default mapAuthCodeToMessage;