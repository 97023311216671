import React, { useState, useContext, useEffect } from "react";
import { Link } from "@material-ui/core";
import { useStyles } from "./styles";

import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import LogoutIcon from "@mui/icons-material/Logout";
// import AppLogo from "../../assets/app-logo.png";
import { logout } from "../../firebase/firebase";
import { UserContext } from "../../contexts/UserContext";
import { withRouter } from "react-router-dom";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { getSidebarFlowNames } from '../../firebase/firebase';

const Sidebar = ({ history, location }) => {
  const classes = useStyles();
  const { setUser, setIsLoggingIn, user } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [flows, setFlows] = useState([]);

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const navItem = [
    { route: "/home", name: "Home", icon: <HomeIcon /> },
    { route: "/add", name: "Add New Flow", icon: <AddIcon /> },
    { route: "", name: "Sign Out", icon: <LogoutIcon /> },
  ];

  const navigate = (item) => {
    if (!item.route) {
      logout();
      setUser("");
      setIsLoggingIn("");
    } else {
      history.push(item.route);
    }
  };

  const getFlows = async () => {
    if (user && user.uid) {
      const data = await getSidebarFlowNames(user.uid);
      setFlows(data);
    };
  };

  useEffect(() => {
    getFlows();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <div className="sidebar-container">
        <div className={classes.itemContainer}>
          {navItem.map((item, index) => (
            item.name === "Home" ? (
              <div>
                <div
                  className={`${classes.navItem} ${index === navItem.length - 1 ? "last-item" : ""
                    }`}
                  key={index}
                  onClick={(event) => navigate(item)}
                >
                  <div className="nav-icon">{item.icon}</div>
                  <div className="nav-name">{item.name}</div>
                  {flows.length ? (
                    <div className="nav-icon" onClick={handleClick}>
                      <span className="nav-icon-span">
                        {open ? <ExpandLess /> : <ExpandMore />}
                      </span>
                    </div>
                  ) : null}
                </div>
                {flows.length ? (
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <div className="nav-name-outer">
                      {flows.map(item => {
                        return (
                          <div className="nav-name-collapse">
                            <Link href={`/flows/${item.id}`} underline="none" color="inherit">
                              {item.flowName}
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </Collapse>
                ) : null}
              </div>
            ) : (
              <div
                className={`${classes.navItem} ${index === navItem.length - 1 ? "last-item" : ""
                  }`}
                key={index}
                onClick={(event) => navigate(item)}
              >
                <div className="nav-icon">{item.icon}</div>
                <div className="nav-name">{item.name}</div>
              </div>
            )
          ))}
        </div>
        {/* <div className="last-item logo-wrapper">
          <div>powered by</div>
          <div className="logo">
            <img src={AppLogo} alt="app-logo" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default withRouter(Sidebar);

