import React, { useState, useEffect, useContext } from "react";
import { Link } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import { colors } from "../../constants/colors";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Button from "@mui/material/Button";
import FlowSummary from "../../components/FlowSummary";
import { withRouter } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { updateFlow, deleteFlow } from "../../firebase/firebase";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import { editFlowName } from "../../firebase/firebase";
import { ToastContext } from "../../contexts/ToastContext";

const FlowOuterSummary = ({
  flowName: flow,
  flowId,
  fetchFlows,
  companies,
  classes,
  history,
  page,
}) => {
  const [flowName, setFlowName] = useState(flow);
  const [editCompany, setEditCompany] = useState(false);
  const { openToast } = useContext(ToastContext);
  const [companies_, setCompanies] = useState(companies);

  useEffect(() => {
    setCompanies(companies);
  }, [companies]);

  const editIcon = (value) => {
    if (page === "flow") {
      return (
        <EditIcon
          className={classes.editIcon}
          onClick={() => setEditCompany(value)}
        />
      );
    } else return null;
  };

  const handleChange = async (e) => {
    const name = e.target.value;
    setFlowName(name);
  };

  const handleSubmit = async (e) => {
    try {
      const name = e.target.value;
      await editFlowName(flowId, name);
      await fetchFlows();
      setFlowName(name);
      setEditCompany(false);
      openToast("Flow name edited successfully!", "success");
    } catch (error) {
      openToast("Error saving flow name");
      console.log(error);
    }
  };

  const handleDeleteFlow = async (e) => {
    e.stopPropagation();
    if (window.confirm("Are you sure you want to delete?")) {
      await deleteFlow(flowId);
      await fetchFlows();
      history.push("/");
    }
  };

  const markFlowSeen = async (e) => {
    e.stopPropagation();
    const seen = companies_.map((item) => {
      return {
        ...item,
        urlInfo: item.urlInfo.map((item) => {
          if (item.referenceImage)
            return {
              ...item,
              imageUrl: item.referenceImage,
              referenceImage: null,
              differenceImage: null,
              changes: 0,
            };
          return item;
        }),
      };
    });
    setCompanies(seen);
    setTimeout(() => {
      seen.map(async (c) => {
        updateFlow(flowId, c.id, c.companyName, c.urlInfo);
      })
    }, 0);
  };

  return (
    <>
      {editCompany ? (
        <div className={classes.header}>
          <div className={classes.headerInput}>
            <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                Flow Name
              </InputLabel>
              <Input
                type={"text"}
                value={flowName}
                onChange={handleChange}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmit(e);
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility">
                      {editIcon(false)}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
        </div>
      ) : (
        <div className={classes.header}>
          <span
            className={classes.headerTitle}
          >
            <Link href={`/flows/${flowId}`} underline="none" color="inherit">
              {flow}
              {page === "view" && <EditIcon
                className={classes.editIcon}
              />}
            </Link>
            <Button
              sx={{ float: "right", color: colors.black }}
              onClick={handleDeleteFlow}
            >
              <DeleteIcon />
            </Button>
            <Button
              sx={{ float: "right", color: colors.black }}
              onClick={markFlowSeen}
            >
              <DoneAllIcon />
            </Button>
          </span>
          {editIcon(true)}
        </div>
      )}
      {companies_.map((item, index) => {
        return (
          <FlowSummary
            key={index}
            companyName={item.companyName}
            companyUpdatedAt={new Date(item.updated.seconds * 1000)}
            urlInfo={item.urlInfo}
            flowId={flowId}
            companyId={item.id}
            fetchFlows={fetchFlows}
            page={page}
          />
        );
      })}
    </>
  );
};

export default withRouter(FlowOuterSummary);
