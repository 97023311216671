import React, { useState, useContext, useEffect } from "react";
import { Link } from "@material-ui/core";
import { trackWebsite } from "../../services/flowServices";
import { useStyles } from "../Home/styles";
import addhttp from "../../utils/addHttp";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { getFlow, addCompanyToFlow } from "../../firebase/firebase";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FlowOuterSummary from "../../components/FlowSummary/flowOuterSummary";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FlowFields from "../../components/FlowFields";
import { useParams } from "react-router-dom";
import { ToastContext } from "../../contexts/ToastContext";
import { useActiveStyles } from "../../components/Header/styles";

const FlowDetail = () => {
  const { flowId } = useParams();
  const classes = useStyles();
  const { openToast } = useContext(ToastContext);
  const [flow, setFlow] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addCompany, setAddCompany] = useState(false);
  const activeClasses = useActiveStyles();

  useEffect(() => {
    fetchFlow();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFlow = async () => {
    try {
      setIsFetching(true);
      const flow = await getFlow(flowId);
      console.log("Flow fetched => ", flow);
      setFlow(flow[0]);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  };

  const submit = async (data) => {
    console.log("Submitting data => ", data);
    if (!data.companyName) {
      return openToast("Invalid or empty field(s)", "error");
    }
    if (data.urlInfo.find((item) => !item.url || !item.urlTitle)) {
      return openToast("Invalid or empty field(s)", "error");
    }
    setIsSubmitting(true);

    data.urlInfo = data.urlInfo.map((item) => {
      return {
        ...item,
        url: addhttp(item.url),
        imageUrl: "",
        referenceImage: "",
        differenceImage: "",
        changes: 0,
      };
    });
    setTimeout(() => {
      data.urlInfo.forEach((item) => {
        trackWebsite({
          flowId: flow.id,
          url: item.url,
          deviceType: "desktop",
          image: "",
        }).then((r) => {
          console.log(r);
        });
      });
    }, 0);

    //Save the flow in the database along with initial tracking info
    await addCompanyToFlow(flow.id, data.companyName, data.urlInfo);

    console.log("Data saved in firestore");
    setIsSubmitting(false);
    setAddCompany(false);
    flow.companies.push(data);
    fetchFlow();
    openToast("Flow saved successfully!", "success");
  };

  return (
    <div className={classes.root}>
      <Sidebar />
      <div className={classes.content}>
        <Header>
          <Link href="/home" underline="hover" color="inherit">
            Home
          </Link>
          <Link underline="hover" color="inherit" classes={activeClasses}>
            {flow && flow.flowName}
          </Link>
        </Header>
        {flow ? (
          <div>
            <FlowOuterSummary
              flowName={flow.flowName}
              companies={flow.companies}
              fetchFlows={fetchFlow}
              classes={classes}
              flowId={flow.id}
              page="flow"
            />
            {addCompany ? (
              <>
                <FlowFields submit={submit} />
                <div
                  className={classes.changeButton}
                  variant="secondary"
                  onClick={(event) => setAddCompany(false)}
                >
                  <RemoveIcon className={classes.changeButtonIcon}>
                    -
                  </RemoveIcon>
                </div>
              </>
            ) : (
              <div
                className={classes.changeButton}
                variant="secondary"
                onClick={(event) => setAddCompany(true)}
              >
                <AddIcon className={classes.changeButtonIcon}>+</AddIcon>
              </div>
            )}
          </div>
        ) : null}
      </div>
      <div></div>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isFetching || isSubmitting}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  );
};

export default FlowDetail;
