import { makeStyles } from "@material-ui/core";
import { colors } from "../../constants/colors";

const stylesByVariant = {
    primary: {
        background: colors.black,
        color: colors.white,
        border: `1px solid ${colors.black}`,
    },
    secondary: {
        background: colors.white,
        color: colors.black,
        border: `1px solid ${colors.black}`,
    },
};

const hoverStylesByVariant = {
    primary: {
        background: colors.black2,
    },
    secondary: {
        background: colors.white2,
    },
};

export const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: ({ variant }) => stylesByVariant[variant].background,
        color: ({ variant }) => stylesByVariant[variant].color,
        border: ({ variant }) => `1px solid ${colors.black}`,
        // borderColor: colors.black,
        borderRadius: '9px',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '30px',
        textTransform: 'none',
        minWidth: '105px',
        width: "100%",
        '&:hover': {
            backgroundColor: ({ variant }) => hoverStylesByVariant[variant].background,
        },
        "@media screen and (max-width: 480px)": {
            fontSize: '12px',

        },
    },
}));
