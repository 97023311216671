import React, { useState, useContext } from "react";
import { Link } from "@material-ui/core";
import { useStyles } from "./styles";
import { useActiveStyles } from "../../components/Header/styles";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import FlowFields from "../../components/FlowFields";
import TextInput from "../../components/TextField";
import { addFlow } from "../../firebase/firebase";
import { UserContext } from "../../contexts/UserContext";
import { ToastContext } from "../../contexts/ToastContext";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import addhttp from "../../utils/addHttp";
import { trackWebsite } from "../../services/flowServices";

const AddFlow = ({ history }) => {
  const classes = useStyles();
  const activeClasses = useActiveStyles();
  const { user } = useContext(UserContext);
  const [flowName, setFlowName] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const { openToast } = useContext(ToastContext);

  const submit = async (data) => {
    try {
      console.log("Submitting data => ", flowName, data);
      if (!flowName) {
        return openToast("Invalid or empty field(s)", "error");
      }
      if (data.urlInfo.find((item) => !item.url || !item.urlTitle)) {
        return openToast("Invalid or empty field(s)", "error");
      }
      setIsFetching(true);

      const urlInfo = data.urlInfo.map((item) => {
        return {
          ...item,
          url: addhttp(item.url),
          imageUrl: "",
          referenceImage: "",
          differenceImage: "",
          changes: 0,
        };
      });

      //Save the flow in the database along with initial tracking info
      const docRef = await addFlow(
        user.uid,
        flowName,
        data.companyName,
        urlInfo
      );

      setTimeout(() => {
        urlInfo.forEach((item) => {
          trackWebsite({
            flowId: docRef.id,
            url: item.url,
            deviceType: "desktop",
            image: "",
          }).then((r) => {
            console.log(r);
          });
        });
      }, 0);

      console.log("Data saved in firestore");
      setIsFetching(false);
      history.push("/home");
      openToast("Flow saved successfully!", "success");
    } catch (error) {
      console.log("Error while adding flow: ", error);
      setIsFetching(false);
      openToast("Internal server error.", "error");
    }
  };

  return (
    <div className={classes.root}>
      <Sidebar />
      <div className={classes.content}>
        <Header>
          <Link underline="hover" color="inherit" classes={activeClasses}>
            + Add a new flow
          </Link>
        </Header>
        <div className={classes.header}>
          <TextInput
            className={classes.flowTitle}
            placeholder="Enter flow name"
            name="flowName"
            value={flowName}
            onChange={(e) => setFlowName(e.target.value)}
          />
        </div>
        <FlowFields submit={submit} />
      </div>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isFetching}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  );
};

export default AddFlow;
