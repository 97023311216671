import React from "react";

import { useStyles } from "../FlowFields/styles";

import Button from "../Button";
import Container from "../Container";
import TextInput from "../TextField";

import { ReactComponent as CrossSvg } from "../../assets/cross.svg";

const EditFlow = (props) => {
  const classes = useStyles();
  const { inputFields, addFields, handleInputChange, submit, removeFields } =
    props;

  return (
    <Container>
      <div style={{ marginTop: "20px" }} className={classes.fields}>
        {inputFields.map((input, index) => {
          return (
            <div key={index} className={classes.lowerFields}>
              <div className={classes.urlTitle}>
                <TextInput
                  placeholder="Please enter a title"
                  label="URL Title"
                  name="urlTitle"
                  value={input.urlTitle}
                  onChange={(event) => handleInputChange(index, event)}
                />
              </div>
              <div className={classes.url}>
                <div className="svgContainer">
                  <TextInput
                    placeholder="Please enter a url"
                    label="URL"
                    name="url"
                    value={input.url}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                  {inputFields.length > 1 ? (
                    <CrossSvg onClick={(event) => removeFields(index)} />
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}

        <div className={classes.lowerFields}>
          <div className={classes.urlTitle}></div>
          <div className={classes.url}>
            <Button variant="secondary" onClick={addFields}>
              +
            </Button>
          </div>
        </div>
        <div className={classes.buttons}>
          {/* <div className={classes.newTagButton}>
            <Button variant="secondary">#add a new tag +</Button>
          </div> */}
          <div></div>
          <div className={classes.saveButton}>
            <Button variant="primary" onClick={submit}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default EditFlow;
