import { makeStyles } from "@material-ui/core";
import { colors } from "../../constants/colors";

export const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'left',
        fontSize: '16px',
        lineHeight: '30px',
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        width: '100%',
        '& a': {
            fontWeight: '700',
            color: colors.grey,
        },
        '& svg': {
            color: colors.grey,
        },
    },
}));

export const useActiveStyles = makeStyles(() => ({
    root: {
        color: `${colors.black} !important`,
    },
}));
