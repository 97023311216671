import React, { useState, useEffect } from "react";
import { Link } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { getUrlDetails } from "../../firebase/firebase";
import { useStyles } from "./styles";
import { useActiveStyles } from "../../components/Header/styles";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import ImgContainer from "./imgContainer";
import isNMinutesPassed from "../../utils/dt";

const URL_MAX_LENGTH = 20;

const formatDate = (dateStr) => {
  var parsedDate = new Date(dateStr);
  return parsedDate.toLocaleString();
};

const getTimezoneLocation = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const getTimezoneOffsetInGMT = () => {
  var currentTime = new Date();
  var currentTimezone = currentTime.getTimezoneOffset();
  currentTimezone = (currentTimezone / 60) * -1;
  var gmt = "GMT";
  if (currentTimezone !== 0) {
    gmt += currentTimezone > 0 ? " +" : " ";
    gmt += currentTimezone;
  }
  return gmt;
};

const stripProtocol = (url) => {
  if (url !== "") {
    const low_url = url.toLowerCase();
    if (low_url.startsWith("https://")) {
      return url.slice(8);
    } else if (low_url.startsWith("http://")) {
      return url.slice(7);
    }
  }
  return url;
};

const trimUrl = (url) => {
  var strippedUrl = stripProtocol(url);
  if (strippedUrl.length > URL_MAX_LENGTH) {
    return `${strippedUrl.slice(0, URL_MAX_LENGTH)}...`;
  }
  return strippedUrl;
};

const urlHasError = (urlDetails) => {
  return urlDetails.error === true || (!urlDetails.imageUrl && isNMinutesPassed(urlDetails.companyUpdatedAt))
}

const Changes = ({ location }) => {
  const { flowId, companyId, urlNumber } = useParams();
  const classes = useStyles();
  const activeClasses = useActiveStyles();
  const [isFetching, setIsFetching] = useState(true);
  const [urlDetails, setUrlDetails] = useState();

  useEffect(() => {
    fetchUrlDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUrlDetails = async () => {
    try {
      setIsFetching(true);
      const details = await getUrlDetails(flowId, companyId, urlNumber);
      console.log("Url details fetched => ", details);
      setUrlDetails(details);
      // Check if the image URL is missing and there's no error.
      if (!details.imageUrl && details.error !== true && !isNMinutesPassed(details.companyUpdatedAt)) {
        // Set a timeout to call the same function again after 1 minute.
        setTimeout(fetchUrlDetails, 60000);
      } else {
        setIsFetching(false);
      }
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.root}>
      <Sidebar />
      <div className={classes.content}>
        {urlDetails && (
          <>
            <Header>
              <Link href="/home" underline="hover" color="inherit">
                Home
              </Link>
              <Link href={`/flows/${flowId}`} underline="hover" color="inherit">
                {urlDetails.flowName}
              </Link>
              <Link href={`/flows/${flowId}`} underline="hover" color="inherit">
                {urlDetails.companyName}
              </Link>
              <Link underline="hover" color="inherit" classes={activeClasses}>
                {urlDetails.urlTitle}
              </Link>
              <Link href={urlDetails.url} underline="hover" color="inherit" classes={activeClasses} target="_blank">
                {trimUrl(urlDetails.url)}
              </Link>
            </Header>
            {urlDetails.referenceImage && (
              <div className={classes.header}>
                {urlDetails.changes === 0 ? (
                  <span>No Change Detected <span className={classes.header2}>since {formatDate(urlDetails.referenceImageMeta.createdAt)}</span></span>
                ) : (
                  "Change Detected"
                )}
              </div>
            )}
            <div className={classes.disclaimer}>
              {`* All times are shown in ${getTimezoneLocation()} time (${getTimezoneOffsetInGMT()})`}
            </div>
            <div className={classes.imageContainerUpper}>
              {urlHasError(urlDetails) ? (
                <div className={classes.error}>
                  {`Error: ${urlDetails.url}`}
                </div>
              ) : (
                urlDetails.imageUrl && (
                  <ImgContainer
                    url={urlDetails.imageUrl}
                    className={classes.image}
                    alt="current-img"
                    title="Now"
                    info={`(${formatDate(urlDetails.imageUrlMeta.createdAt)})`}
                  />
                )
              )}
              {urlDetails.referenceImage && (
                <ImgContainer
                  url={urlDetails.referenceImage}
                  className={classes.referenceImage}
                  alt="reference-img"
                  title="Reference"
                  info={`(${formatDate(urlDetails.referenceImageMeta.createdAt)})`}
                />
              )}
            </div>
            {urlDetails.differenceImage && (
              <ImgContainer
                url={urlDetails.differenceImage}
                className={classes.differenceImage}
                alt="difference-img"
                title="Difference"
              />
            )}
          </>
        )}
      </div>
      <div></div>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isFetching}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  );
};

export default Changes;
