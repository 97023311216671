import React, { createContext, useState } from "react";

const ToastContext = createContext();

const ToastProvider = (props) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");

  const openToast = (message, status) => {
    setOpen(true);
    setMessage(message);
    setStatus(status);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <ToastContext.Provider
      value={{
        openToast,
        handleClose,
        open,
        message,
        status,
      }}
    >
      {props.children}
    </ToastContext.Provider>
  );
};

export { ToastContext, ToastProvider };
