import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  fields: {
    display: "flex",
    flexDirection: "column",
    gap: "50px",
  },
  upperFields: {
    width: "300px",
    '@media(max-width: 480px)': {
      width: "100%"
    }
  },
  lowerFields: {
    display: "flex",
    justifyContent: "space-between",
    gap: "10%",
    '@media(max-width: 480px)': {
      flexDirection: "column",
      gap: "10px",
      // width: "100%"
    }
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "20px"
  },
  urlTitle: {
    width: "40%",
    '@media(max-width: 480px)': {
      width: "100%"
    }
  },
  url: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    rowGap: "30px",
    width: "40%",
    '@media(max-width: 480px)': {
      width: "100%"
    }
  },
  newTagButton: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    flexWrap: "wrap"
  },
  saveButton: {
    width: "110px",
  },
  topFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  companyName: {
    width: "40%",
  },
  flowName: {
    width: "40%",
  },
}));
