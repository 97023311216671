import React, { useState, useContext } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { useStyles } from "./styles";
import TextInput from "../../components/TextField";
import Button from "../../components/Button";
import { signInWithEmailAndPassword } from "../../firebase/firebase";
import { ToastContext } from "../../contexts/ToastContext";
import mapErrorCode from "../../utils/mapErrorCode";

const Login = ({ history }) => {
  const classes = useStyles();
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = userData;
  const { openToast } = useContext(ToastContext);

  const handleSubmit = async (event) => {
    console.log("Handle submit => ", event);

    try {
      await signInWithEmailAndPassword(email, password);
      setUserData({
        ...userData,
        email: "",
        password: "",
      });
    } catch (error) {
      console.error(error);
      openToast(mapErrorCode(error.code), 'error');
    }
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  return (
    <FormGroup>
      <div className={classes.root}>
        <div className={classes.adornment} />
        <div className={classes.container}>
          <div className={classes.content}>
            <div className={classes.header}>Welcome back!</div>
            <div className={classes.subheader}>
              Enter your email and password to log in!
            </div>
            <div className={classes.field}>
              <TextInput
                label="Email"
                placeholder="mail@example.com"
                labelSize="small"
                name="email"
                type="email"
                value={email}
                onChange={handleChange}
              />
            </div>
            <div className={classes.field}>
              <TextInput
                label="Password"
                placeholder="Min. 8 characters"
                labelSize="small"
                name="password"
                type="password"
                value={password}
                onChange={handleChange}
              />
            </div>
            <div className={classes.options}>
              <FormControlLabel
                control={<Checkbox defaultChecked color="default" />}
                label="Keep me logged in"
              />
              <div className={classes.spanLink} onClick={() => history.push("/forgot-password")}>Forgot password?</div>
            </div>
            <Button onClick={() => handleSubmit()}>Login</Button>
            <div className={classes.footer}>
              Not registered yet?{" "}
              <span
                className={classes.link}
                underline="hover"
                onClick={() => history.push("/register")}
              >
                Create an Account
              </span>
            </div>
          </div>
        </div>
      </div>
    </FormGroup>
  );
};

export default Login;
