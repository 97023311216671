import React, { useState } from "react";

import { useStyles } from "./styles";

import Button from "../Button";
import Container from "../Container";
import TextInput from "../TextField";
import AddIcon from "@mui/icons-material/Add";

import { ReactComponent as CrossSvg } from "../../assets/cross.svg";
import Chip from "@mui/material/Chip";

const FlowFields = ({
  submit
}) => {
  const classes = useStyles();
  const [tagValue, setTagValue] = useState("");
  const [tagList, setTagList] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [inputFields, setInputFields] = useState([{ urlTitle: "", url: "" }]);

  const onTagInputChange = (value) => {
    setTagValue(value);
  };

  const addFields = () => {
    let newfield = { urlTitle: "", url: "" };
    setInputFields([...inputFields, newfield]);
  };

  const handleInputChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  const removeFields = (index) => {
    const data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const addTag = () => {
    const data = [...tagList];
    data.push(tagValue);
    setTagList(data);
    setTagValue("");
  };

  const handleDelete = (index) => {
    const data = [...tagList];
    data.splice(index, 1);
    setTagList(data);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") addTag();
  };

  const handleSubmit = () => {
    const formObj = {
      companyName: companyName,
      urlInfo: inputFields
    }
    submit(formObj)
  }

  return (
    <Container>
      <div style={{ marginTop: "20px" }} className={classes.fields}>
        <div className={classes.upperFields}>
          <TextInput
            placeholder="Please enter a company name"
            label="Company"
            name="companyName"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>
        {inputFields.map((input, index) => {
          return (
            <div key={index} className={classes.lowerFields}>
              <div className={classes.urlTitle}>
                <TextInput
                  placeholder="Please enter a title"
                  label="URL Title"
                  name="urlTitle"
                  value={input.urlTitle}
                  onChange={(event) => handleInputChange(index, event)}
                />
              </div>
              <div className={classes.url}>
                <div className="svgContainer">
                  <TextInput
                    placeholder="Please enter a url"
                    label="URL"
                    name="url"
                    value={input.url}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                  {inputFields.length > 1 ? (
                    <CrossSvg onClick={(event) => removeFields(index)} />
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}

        <div className={classes.lowerFields}>
          <div className={classes.urlTitle}></div>
          <div className={classes.url}>
            <Button variant="secondary" onClick={addFields}>
              +
            </Button>
          </div>
        </div>
        <div className={classes.buttons}>
          <div className={classes.newTagButton}>
            {tagList.map((item, index) => (
              <Chip
                key={index}
                label={item}
                onDelete={(event) => handleDelete(index)}
              />
            ))}
            <div className="inputWithButton">
              <input
                type="text"
                placeholder="#Add a new tag"
                className="btn-tag"
                value={tagValue}
                onInput={(event) => onTagInputChange(event.target.value)}
                onKeyDown={(event) => handleKeyDown(event)}
              />
              <AddIcon className="tag-icon" onClick={addTag} />
            </div>
          </div>
          <div className={classes.saveButton}>
            <Button variant="primary" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FlowFields;
