import React, { useState } from "react";
import { useStyles } from "./styles";
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from "file-saver";

const ImgContainer = ({ url, className, alt, title, info = "" }) => {
  const classes = useStyles();
  const [isHovered, setHover] = useState(false);

  const downloadImage = () => {
    saveAs(url, "tracking-image.png");
  };

  return (
    <>
      <div
        className={className}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{ position: 'relative' }}
      >
        <span className={classes.title}>{`${title}    `}<span className={classes.title2}>{info}</span></span>
        <img src={url} alt={alt} />
        {isHovered && (
          <Button
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              backgroundColor: "#929eaa",
            }}
            variant="contained"
            endIcon={<DownloadIcon />}
            onClick={() => {
              downloadImage();
            }}
          >
            Download
          </Button>
        )}
        <span className={classes.title}>{`${title}    `}<span className={classes.title2}>{info}</span></span>
      </div>
    </>
  );
};

export default ImgContainer;
